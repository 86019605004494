import {useEffect, useState} from 'react'
import type {Timer} from '../@types/timeout'

function throttle(cb: () => void, ms: number) {
  let lastTime = 0

  return () => {
    const now = Date.now()
    if (now - lastTime >= ms) {
      cb()
      lastTime = now
    }
  }
}

export function useIdle(ms: number): boolean {
  const [idle, setIdle] = useState(false)

  useEffect(() => {
    let timeoutId: Timer

    const handleTimeout = () => {
      setIdle(true)
    }

    const handleEvent = throttle(() => {
      setIdle(false)

      window.clearTimeout(timeoutId)
      timeoutId = setTimeout(handleTimeout, ms)
    }, 1_000)

    const handleVisibilityChange = () => {
      if (!document.hidden) {
        handleEvent()
      }
    }

    timeoutId = setTimeout(handleTimeout, ms)

    const controller = new AbortController()
    window.addEventListener('mousemove', handleEvent, {signal: controller.signal})
    window.addEventListener('mousedown', handleEvent, {signal: controller.signal})
    window.addEventListener('resize', handleEvent, {signal: controller.signal})
    window.addEventListener('keydown', handleEvent, {signal: controller.signal})
    window.addEventListener('touchstart', handleEvent, {signal: controller.signal})
    window.addEventListener('wheel', handleEvent, {signal: controller.signal})
    document.addEventListener('visibilitychange', handleVisibilityChange, {signal: controller.signal})

    return () => {
      controller.abort()
      window.clearTimeout(timeoutId)
    }
  }, [ms])

  return idle
}
