import {memo, type PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'
import {AbortError, ApiError, NetworkError} from '../../utils/ApiError'
import {ErrorHttp} from './ErrorHttp'
import {ErrorPage} from './ErrorPage'

interface Props extends PropsWithChildren {
  error: Error
}

export const ErrorError = memo(({error, children}: Props) => {
  const {t} = useTranslation()

  if (error instanceof AbortError) {
    return null
  }

  if (error instanceof NetworkError || error.message.includes('Failed to fetch dynamically imported module')) {
    return <ErrorPage
      title={t('error.http.network.title')}
      body={t('error.http.network.body')}
    >
      {children}
    </ErrorPage>
  }

  if (error instanceof ApiError) {
    if (error.statusCode === 404) {
      return <ErrorHttp code={404}>{children}</ErrorHttp>
    }
    if (error.statusCode >= 500) {
      return <ErrorHttp code={500}>{children}</ErrorHttp>
    }

    return <ErrorPage
      title={error.message}
    >
      {children}
    </ErrorPage>
  }

  return <ErrorPage
    title={t('error.generic')}
  >
    {children}
  </ErrorPage>
})
