import {memo, type PropsWithChildren} from 'react'
import {useTranslation} from 'react-i18next'
import {ErrorPage} from './ErrorPage'

interface Props extends PropsWithChildren {
  code: 404 | 500
}

export const ErrorHttp = memo(({code, children}: Props) => {
  const {t} = useTranslation()

  return <ErrorPage
    title={t(`error.http.${code}.title`)}
    body={t(`error.http.${code}.body`)}
  >
    {children}
  </ErrorPage>
})
