import {useRef} from 'react'
import {useSWRConfig} from 'swr'
import type {Iri} from '../@types/Api'
import type {UserCurrent} from '../@types/entity/User'
import type {HydraResource} from '../@types/hydra/HydraResource'
import {createCollectionIri} from '../utils/iri'
import {useUserHelperGetCurrent} from './entity/useUser'
import {useEffectEvent} from './useEffectEvent'

const watchingSets: Set<string>[] = []

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

type Mutate = (key: string) => Promise<void>
type MutationAction<T> = (mutate: Mutate) => Promise<T>
type MutateTagsFallback = <T>(primaryAction: MutationAction<T>, delay?: number) => Promise<T>

export function useMutateTagsFallback(): MutateTagsFallback {
  const mutateTags = useMutateTags()

  return useEffectEvent(async <T>(primaryAction: MutationAction<T>, delay = 300): Promise<T> => {
    const watching = new Set<string>()
    watchingSets.push(watching)
    let end: number | undefined

    const mutate: Mutate = async (key: string) => {
      end ??= Date.now() + delay
      while (Date.now() < end) {
        if (watching.has(key)) {
          return
        }

        await sleep(50)
      }

      if (watching.has(key)) {
        return
      }

      await mutateTags([key])
    }

    try {
      return await primaryAction(mutate)
    } finally {
      const pos = watchingSets.indexOf(watching)
      if (pos >= 0) {
        watchingSets.splice(pos, 1)
      }
    }
  })
}

export function useMutateTags(): (tags: string[]) => Promise<void> {
  const {cache, mutate} = useSWRConfig()

  return useEffectEvent(async (tags: string[]): Promise<void> => {
    const mutations = []
    for (const key of cache.keys()) {
      const state = cache.get(key)
      const cacheTags = state?.data?.['@tags']
      if (!cacheTags) {
        continue
      }

      if (cacheTags.find((value: string) => tags.includes(value))) {
        mutations.push(mutate(key))
        for (const watchingSet of watchingSets) {
          for (const cacheTag of cacheTags) {
            watchingSet.add(cacheTag)
          }
        }
      }
    }

    await Promise.all(mutations)
  })
}

export function useMutateResource(): (resourceIri: Iri, item?: HydraResource) => Promise<void> {
  const {user} = useUserHelperGetCurrent()
  const {mutate} = useSWRConfig()
  const userRef = useRef<UserCurrent | undefined>(user)
  userRef.current = user

  return useEffectEvent(async (resourceIri: Iri, item?: HydraResource): Promise<void> => {
    const mutations = []
    if (item === undefined) {
      mutations.push(mutate(resourceIri))
    } else {
      mutations.push(mutate(resourceIri, item))
    }
    if (userRef.current?.['@id'] === resourceIri) {
      mutations.push(mutate(createCollectionIri('me')))
    }

    await Promise.all(mutations)
  })
}
