import {atom, useAtomValue, useSetAtom} from 'jotai'
import type {Setter} from '../@types/setter'

const tokenAtom = atom<string | null | undefined>(undefined)
const loggedAtom = atom<boolean | undefined>((get) => {
  const token = get(tokenAtom)

  if (token === undefined) {
    return undefined
  }

  return token !== null
})

export function useLogged(): boolean | undefined {
  return useAtomValue(loggedAtom)
}

export function useStateToken(): [string | null | undefined, Setter<string | null>] {
  return [useToken(), useSetToken()]
}

export function useToken(): string | null | undefined {
  return useAtomValue(tokenAtom)
}

export function useSetToken(): Setter<string | null> {
  return useSetAtom(tokenAtom) as Setter<string | null>
}
