import i18n from 'i18next'
import {type NavigateOptions, useNavigate} from 'react-router'
import {useCurrentSpotSlug} from '../states/currentSpot'
import {useEffectEvent} from './useEffectEvent'

export function getFullUrl(to = ''): string {
  return `${document.location.origin}${to}`
}

export function getLangUrl(to = ''): string {
  return `/${i18n.resolvedLanguage}${to}`
}

export function getSpotUrl(slug: string, to = ''): string {
  return getLangUrl(`/spot/${slug}${to}`)
}

export function useSpotPath(): undefined | string {
  const slug = useCurrentSpotSlug()

  if (!slug) {
    return undefined
  }

  return getSpotUrl(slug)
}

export function useSpotUrl<T extends boolean = false>(to = '', fallbackToRoot?: T): T extends true ? string : undefined | string {
  const slug = useCurrentSpotSlug()

  if (!slug) {
    if (!(fallbackToRoot ?? false)) {
      return undefined as T extends true ? string : undefined | string
    }
    return getLangUrl(to)
  }

  return getSpotUrl(slug, to)
}

export function useSpotNavigate(): (to: string, options?: NavigateOptions) => void {
  const navigate = useNavigate()
  const spotPath = useSpotPath()

  return useEffectEvent((to: string, options: NavigateOptions = {}): void => {
    navigate(`${spotPath}/${to}`, options)
  })
}
