import {forwardRef, memo, type PropsWithChildren, type Ref} from 'react'
import {dialogContentClasses, GlobalStyles} from '@mui/material'
import CssBaseline from '@mui/material/CssBaseline'
import {grey} from '@mui/material/colors'
import ThemeProvider from '@mui/material/styles/ThemeProvider'
import createTheme from '@mui/material/styles/createTheme'
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes'
import {Link as RouterLink, type LinkProps as RouterLinkProps} from 'react-router'
import DefaultPropsProvider from '@mui/material/DefaultPropsProvider'
import type {ComponentsPropsList} from '@mui/material/styles/props'

const LinkBehavior = memo(forwardRef((props: Omit<RouterLinkProps, 'to'> & {
  href: RouterLinkProps['to']
}, ref: Ref<HTMLAnchorElement>) => {
  const {href, ...other} = props
  return <RouterLink ref={ref} to={href} {...other} />
}))

type defaultPropsType = {
  [P in keyof ComponentsPropsList]?: Partial<ComponentsPropsList[P]>;
}
const defaultProps: defaultPropsType = {
  MuiTooltip: {
    enterDelay: 500,
    enterNextDelay: 100,
  },
  MuiFormControl: {
    margin: 'dense',
    fullWidth: true,
  },
  MuiTextField: {
    margin: 'dense',
    fullWidth: true,
  },
  MuiLink: {
    component: LinkBehavior,
  },
  MuiButtonBase: {
    LinkComponent: LinkBehavior,
  },
  MuiListItem: {
    component: 'div',
  },
  MuiList: {
    component: 'div',
  },
}

const appTheme = responsiveFontSizes(createTheme({
  palette: {
    secondary: {
      main: grey[600],
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        select: {
          minHeight: '1.4375em',
          maxHeight: '1.4375em',
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          [`+ form > .${dialogContentClasses.root}`]: {
            paddingTop: 0,
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        message: {
          flex: 1,
        },
      },
    },
  },
}))

export const AppTheme = memo(({children}: PropsWithChildren) => {
  return <ThemeProvider theme={appTheme}>
    <CssBaseline/>
    <GlobalStyles
      styles={{
        body: {backgroundColor: grey['100']},
        '.ellipsis-container': {
          minWidth: '1px', // cannot use overflow: hidden without breaking button that use negative margins
          maxWidth: '100%',
        },
        '.nowrap': {
          whiteSpace: 'nowrap',
        },
        '.ellipsis': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
        '.flex-row': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: appTheme.spacing(1),
        },
        '.flex-column': {
          display: 'flex',
          flexDirection: 'column',
          gap: appTheme.spacing(1),
        },
        '.flex-row-space': {
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: appTheme.spacing(1),
          justifyContent: 'space-between',
          flex: 1,
        },
        'input[type=number]': {
          MozAppearance: 'textfield',
          '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      }}
    />
    <DefaultPropsProvider value={defaultProps}>
      {children}
    </DefaultPropsProvider>
  </ThemeProvider>
})
