import type {FetchOptions} from '../@types/fetch'
import {useSetToken} from '../states/auth'
import {ApiError} from '../utils/ApiError'
import {UserDisconnectedEvent} from '../utils/event'
import eventDispatcher from '../utils/eventDispatcher'
import {fetchApiResourceAuthenticated} from '../utils/fetchApi'
import {createCollectionIri} from '../utils/iri'
import {parseJwt} from '../utils/jwt'
import {useEffectEvent} from './useEffectEvent'
import {useFetcher} from './useFetcher'
import {useMutateResource} from './useMutate'

let currentToken: string | null

export function useLogout(): () => void {
  const setToken = useSetToken()
  const {abortAll} = useFetcher()

  return useEffectEvent(() => {
    currentToken = null

    abortAll()

    setToken(null)
  })
}

export function useLogin(): (token: string) => void {
  const setToken = useSetToken()
  const {abortAll} = useFetcher()
  const mutateResource = useMutateResource()

  return useEffectEvent(async (token: string) => {
    if (currentToken === token) {
      return
    }

    if (currentToken && parseJwt(currentToken).user_identifier === parseJwt(token).user_identifier) {
      // same token
      currentToken = token
      setToken(token)

      return
    }

    abortAll()

    currentToken = token
    await mutateResource(createCollectionIri('me'))

    setToken(token)
  })
}

export function useRefresh(): () => void {
  const login = useLogin()
  const logout = useLogout()

  return useEffectEvent(async () => {
    if (!currentToken) {
      return
    }
    const token: string = currentToken
    try {
      const response = await fetchApiResourceAuthenticated(currentToken, createCollectionIri('auth/jwt'))

      // check if token changed in the meantime
      if (token !== currentToken) {
        return
      }

      login(response.token)
    } catch (e) {
      if (e instanceof ApiError && [401, 403].includes(e.statusCode)) {
        eventDispatcher.dispatchEvent(new UserDisconnectedEvent())
        logout()
      }
    }
  })
}

export function useAuthenticatedFetcher(): (key: string, init?: FetchOptions) => any {
  const logout = useLogout()
  const {fetcher} = useFetcher()

  return useEffectEvent(async (resource: string, init?: FetchOptions): Promise<any> => {
    if (currentToken) {
      try {
        return await fetchApiResourceAuthenticated(currentToken, resource, init)
      } catch (e) {
        if (e instanceof ApiError && e.statusCode === 401) {
          eventDispatcher.dispatchEvent(new UserDisconnectedEvent())
          logout()
        }

        throw e
      }
    } else {
      return await fetcher(resource, init)
    }
  })
}
