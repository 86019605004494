import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai'
import type {Iri} from '../@types/Api'
import type {Setter} from '../@types/setter'

interface SpotIdentifier {
  iri: Iri
  slug: string
}

export const notFoundIdentifier = {
  iri: '/spots/00000000-0000-0000-0000-000000000000',
  slug: '',
}

const currentSpotIdAtom = atom<SpotIdentifier | undefined>(undefined)
const currentSpotIriAtom = atom<Iri | undefined>((get) => {
  return get(currentSpotIdAtom)?.iri
})
const currentSpotSlugAtom = atom<string | undefined>((get) => {
  return get(currentSpotIdAtom)?.slug
})

export function useStateCurrentSpotId(): [SpotIdentifier | undefined, Setter<SpotIdentifier | undefined>] {
  return useAtom(currentSpotIdAtom)
}

export function useCurrentSpotId(): SpotIdentifier | undefined {
  return useAtomValue(currentSpotIdAtom)
}

export function useSetCurrentSpotId(): Setter<SpotIdentifier | undefined> {
  return useSetAtom(currentSpotIdAtom)
}

export function useCurrentSpotIri(): Iri | undefined {
  return useAtomValue(currentSpotIriAtom)
}

export function useCurrentSpotSlug(): Iri | undefined {
  return useAtomValue(currentSpotSlugAtom)
}
