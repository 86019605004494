import {memo, useEffect, useRef} from 'react'
import {useLocation, useNavigate, useParams} from 'react-router'
import {useSpotCollection, useSpotGet} from '../../../../hooks/entity/useSpot'
import {getSpotUrl} from '../../../../hooks/useSpotNavigate'
import {notFoundIdentifier, useStateCurrentSpotId} from '../../../../states/currentSpot'
import {setPromptEnable} from '../../../../states/promptEnable'
import {ApiError} from '../../../../utils/ApiError'

export const CurrentSpotIdListener = memo(() => {
  const [currentSpotId, setCurrentSpotId] = useStateCurrentSpotId()
  const {spotSlug} = useParams()
  const {spots} = useSpotCollection({slug: spotSlug})
  const {spot, error} = useSpotGet(currentSpotId?.iri)

  const navigate = useNavigate()
  const location = useLocation()
  const locationRef = useRef(`${location.pathname}${location.search}`)
  locationRef.current = `${location.pathname}${location.search}`

  useEffect(() => {
    if (!spots) {
      if (!spotSlug) {
        setCurrentSpotId(undefined)
      }
      return
    }

    const spot = spots.member[0]
    const newIdentifier = spot ? {
      iri: spot['@id'],
      slug: spot.slug,
    } : notFoundIdentifier

    setCurrentSpotId((oldIdentifier) => {
      // No slug => undefined in all ways
      if (!spotSlug) {
        return undefined
      }

      // The new identifier is better than the old
      if (!oldIdentifier || oldIdentifier === notFoundIdentifier) {
        return newIdentifier
      }

      // The new identifier is not yet fully loaded
      if (newIdentifier === notFoundIdentifier) {
        // The old identifier still match
        if (oldIdentifier.slug === spotSlug) {
          return oldIdentifier
        }

        return newIdentifier
      }

      // return old identifier to get the same object reference
      if (JSON.stringify(oldIdentifier) === JSON.stringify(newIdentifier)) {
        return oldIdentifier
      }

      return newIdentifier
    })
  }, [setCurrentSpotId, spotSlug, spots])

  useEffect(() => {
    if (!currentSpotId || currentSpotId === notFoundIdentifier) {
      return
    }
    if (error && error instanceof ApiError && error.statusCode === 404) {
      setCurrentSpotId(notFoundIdentifier)

      return
    }

    if (!spot) {
      // an error occurred
      return
    }

    if (spot.slug === currentSpotId.slug) {
      return
    }
    const oldSpotPath = getSpotUrl(currentSpotId.slug, '/')
    if (!locationRef.current.startsWith(oldSpotPath)) {
      return
    }

    setPromptEnable(false)
    navigate(getSpotUrl(spot.slug, locationRef.current.substring(oldSpotPath.length - 1)))
    setPromptEnable(true)

    setCurrentSpotId({
      iri: spot['@id'],
      slug: spot.slug,
    })
  }, [error, navigate, setCurrentSpotId, spot, currentSpotId])

  return null
})
