import type {LocaleObject} from '../@types/Locale'

export const locales: LocaleObject[] = [
  {
    code: 'en',
    label: 'English',
  },
  {
    code: 'fr',
    label: 'Français',
  },
]
