import {useParams} from 'react-router'
import useSWR from 'swr'
import type {Iri} from '../../@types/Api'
import type {SpotCollection, SpotItem, SpotPartial} from '../../@types/entity/Spot'
import type {FetchOptions} from '../../@types/fetch'
import type {FetchOption} from '../../components/EnhancedTable/types'
import {useCurrentSpotIri} from '../../states/currentSpot'
import {createCollectionIri, createItemIri} from '../../utils/iri'
import {type FilterOption, filterToQueryString, useDelete, useGet, usePatch, usePost, useUpsert} from '../useApi'
import {useEffectEvent} from '../useEffectEvent'
import {useFetcher} from '../useFetcher'

const resource = createCollectionIri('spots')

type GetResponse = {
  readonly spot?: SpotItem
  readonly error?: Error
}

type CollectionResponse = {
  readonly spots?: SpotCollection
  readonly error?: Error
}

type SpotCollectionFilter = {
  readonly slug?: string
  readonly organizationIri?: Iri
  readonly organizationIris?: Iri[]
  readonly spotIri?: Iri
  readonly spotIris?: Iri[]
}
const collectionFilterOptions: FilterOption<SpotCollectionFilter> = {
  slug: 'slug',
  spotIri: 'id',
  spotIris: 'id',
  organizationIri: 'organization',
  organizationIris: 'organization',
}
export const useSpotGet = (spotIri?: Iri): GetResponse => {
  const {data, error} = useGet(spotIri)

  return {
    spot: data,
    error,
  }
}

export const useSpotCollection = (filter: SpotCollectionFilter | undefined, fetchOptions?: FetchOption, enabled = true): CollectionResponse => {
  const {
    empty,
    querystring,
  } = filterToQueryString<SpotCollectionFilter>(collectionFilterOptions, enabled ? filter : undefined, fetchOptions)
  querystring.set('pagination', 'false')
  const {data, error} = useSWR(enabled && !empty ? `${createCollectionIri('spots')}?${querystring.toString()}` : null)
  return {
    spots: data,
    error,
  }
}

export const useSpotIriFromLocation = (): string | undefined => {
  const {spotId} = useParams()

  return createItemIri('spots', spotId)
}
export const useSpotIriFromCurrentSpot = (): string | undefined => {
  return useCurrentSpotIri()
}

export const useSpotHelperGetCurrent = (): GetResponse => {
  return useSpotGet(useSpotIriFromCurrentSpot())
}

export function useSpotsFetcher(): (qs: Record<string, string>, init: FetchOptions) => Promise<SpotCollection> {
  const {fetcher} = useFetcher()

  return useEffectEvent((qs: Record<string, string> = {}, init: FetchOptions = {}): Promise<SpotCollection> => {
    let url = resource
    const querystring = new URLSearchParams(qs)
    querystring.set('active', 'true')
    querystring.set('visible', 'true')
    url = `${url}?${querystring.toString()}`

    return fetcher(url, init)
  })
}

type UpdatePayload = Partial<CreatePayload>

type CreatePayload = {
  name: string

  organization?: Iri | null
  routeScale?: string
  boulderScale?: string
  visible?: boolean
  anonymousComment?: boolean
}

export function useSpotCreate(): (payload: CreatePayload) => Promise<SpotItem> {
  return usePost<SpotItem>('spots')
}

export function useSpotUpdate(): (spot: SpotPartial, payload: UpdatePayload) => Promise<SpotItem> {
  return usePatch<SpotItem>()
}

export function useSpotUpsert(): (
spot: SpotPartial | undefined,
payload: UpdatePayload,
createExtraPayload?: Omit<CreatePayload, keyof UpdatePayload>,
) => Promise<SpotItem> {
  return useUpsert(useSpotCreate(), useSpotUpdate())
}

export function useSpotDelete(): (spot: SpotPartial) => Promise<void> {
  return useDelete()
}
