import {atom, useAtom, useAtomValue, useSetAtom} from 'jotai'
import type {BeforeInstallPromptEvent} from '../@types/BeforeInstallPromptEvent'
import type {Setter} from '../@types/setter'

const installPromptAtom = atom<BeforeInstallPromptEvent | undefined>(undefined)

export function useStateInstallPrompt(): [BeforeInstallPromptEvent | undefined, Setter<BeforeInstallPromptEvent | undefined>] {
  return useAtom(installPromptAtom)
}

export function useInstallPrompt(): BeforeInstallPromptEvent | undefined {
  return useAtomValue(installPromptAtom)
}

export function useSetInstallPrompt(): Setter<BeforeInstallPromptEvent | undefined> {
  return useSetAtom(installPromptAtom)
}
