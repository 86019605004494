import {memo, useEffect} from 'react'
import type {BeforeInstallPromptEvent} from '../../../../@types/BeforeInstallPromptEvent'
import {useSetInstallPrompt} from '../../../../states/installPrompt'

export const InstallPromptListener = memo(() => {
  const setInstallPrompt = useSetInstallPrompt()

  useEffect(() => {
    if (!window.verticaltopo?.beforeinstallprompt) {
      return
    }

    setInstallPrompt(window.verticaltopo.beforeinstallprompt)
  }, [setInstallPrompt])

  useEffect(() => {
    const installPromptHandler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault()
      setInstallPrompt(event)
    }
    const installedAppHandler = () => {
      setInstallPrompt(undefined)
    }

    const controller = new AbortController()
    window.addEventListener('beforeinstallprompt', installPromptHandler, {signal: controller.signal})
    window.addEventListener('appinstalled', installedAppHandler, {signal: controller.signal})

    return () => {
      setInstallPrompt(undefined)
      controller.abort()
    }
  }, [setInstallPrompt])

  return null
})
