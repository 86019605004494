import {useRegisterSW} from 'virtual:pwa-register/react'
import {memo, useEffect, useRef, useState} from 'react'
import {APP_VERSION} from '../config'
import {notificationNewReleaseEventName} from '../enums/event'
import {useMeta} from '../hooks/entity/useMeta'
import {useEffectEvent} from '../hooks/useEffectEvent'
import {useIdle} from '../hooks/useIdle'
import eventDispatcher from '../utils/eventDispatcher'
import {useStorageState} from 'react-use-storage-state'
import dayjs from 'dayjs'

const idleTimeout = 5 * 60 * 1000 // 5min force refresh the page after X minutes of inactivity
const updateInterval = 5 * 60 * 1000 // 5min update the SW every X minutes

const isVeryOldVersion = APP_VERSION === 'dev' ? false : (dayjs().diff(dayjs(APP_VERSION, 'YYYYMMDD-HHiiss').diff()) > 60 * 86_400_000)

type RefreshAvailableState = {
  counter: number
  version: string
}

const sleep = (ms: number): Promise<void> => {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export const ReleaseHandler = memo(() => {
  const {meta} = useMeta(APP_VERSION !== 'dev')
  const idle = useIdle(idleTimeout)
  const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration | undefined>()
  const [postponeUpdate, setPostponeUpdate] = useState(false)
  const isMountedRef = useRef(false)
  const loadedAt = useRef(Date.now())

  const [refreshAvailable, setRefreshAvailable] = useState(false)
  const [refreshRequested, setRefreshRequested] = useState(isVeryOldVersion)
  const [refreshRequestedAt, setRefreshRequestedAt] = useStorageState<number | null>('refresh_requested_at', null)
  const [refreshAvailableState, setRefreshAvailableState] = useStorageState<RefreshAvailableState>('refresh_available_state', {
    counter: 0,
    version: APP_VERSION,
  })

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(scriptUrl, swRegistration) {
      if (!isMountedRef.current) {
        return
      }
      setSwRegistration(swRegistration)
    },
  })

  const updateSw = useEffectEvent(async () => {
    const swr = swRegistration
    if (!swr) {
      setPostponeUpdate(true)
      return
    }

    try {
      await swr.update()
    } catch {
      // noop
    }
  })
  const refreshSw = useEffectEvent(async () => {
    if (!updateServiceWorker) {
      return
    }

    const now = Date.now()
    // avoid infinite refresh loop, refresh once per 10min max
    if (refreshRequestedAt !== null && (now - refreshRequestedAt) < 600_000) {
      return
    }

    // reload the page in certain conditions
    const reload = idle || refreshAvailableState.counter > 10 || isVeryOldVersion

    try {
      await updateServiceWorker(reload)
      setRefreshRequestedAt(Date.now())
    } finally {
      if (reload) {
        await sleep(1_000)
        window.location.reload()
      }
    }
  })

  // Do the refresh once ready
  useEffect(() => {
    if (!refreshRequested || !refreshAvailable) {
      return
    }

    refreshSw()
  }, [refreshAvailable, refreshRequested, refreshSw])

  // maintain a reference that tell if the component is mounted
  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // update the SW if postponed (happens when something ask for update but sw was not yet ready)
  useEffect(() => {
    if (swRegistration && postponeUpdate) {
      updateSw()
    }
  }, [postponeUpdate, swRegistration, updateSw])

  // periodically update the SW
  useEffect(() => {
    const timeoutTimer = setTimeout(updateSw, 5_000)
    const timeoutInterval = setInterval(updateSw, updateInterval)

    return () => {
      clearTimeout(timeoutTimer)
      clearInterval(timeoutInterval)
    }
  }, [updateSw])

  // Trigger update when receiving mercure event
  useEffect(() => {
    const controller = new AbortController()
    eventDispatcher.addEventListener(notificationNewReleaseEventName, () => {
      updateSw()
    }, {signal: controller.signal})

    return () => {
      controller.abort()
    }
  }, [updateSw])

  // Trigger update when receiving a request with newer version
  useEffect(() => {
    if (!meta) {
      return
    }
    if (APP_VERSION === 'dev') {
      return
    }
    if (meta.clientVersion > APP_VERSION) {
      updateSw()
    }
  }, [meta, updateSw])

  // Mark refresh available once
  useEffect(() => {
    if (!needRefresh) {
      return
    }

    setRefreshAvailable(true)
  }, [needRefresh])

  // request for refresh when user is IDLE
  useEffect(() => {
    if (!idle || !refreshAvailable) {
      return
    }

    setRefreshRequested(true)
  }, [idle, refreshAvailable])

  // increment the number of time user should have update the application
  useEffect(() => {
    setRefreshAvailableState((previous) => {
      return {
        counter: (previous.version === APP_VERSION ? previous.counter : 0) + (refreshAvailable ? 1 : 0),
        version: APP_VERSION,
      }
    })
  }, [refreshAvailable, setRefreshAvailableState])

  // force refresh if app just started
  useEffect(() => {
    if (!refreshAvailable) {
      return
    }

    const delay = ((refreshAvailableState.counter - 1) * 1_000)
    if ((Date.now() - loadedAt.current) > delay) {
      return
    }

    setRefreshRequested(true)
  }, [refreshAvailable, refreshAvailableState.counter])

  return null
})
