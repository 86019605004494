import dayjs from 'dayjs'
import {atom, useAtomValue, useSetAtom} from 'jotai'
import {type Dispatch, useCallback} from 'react'

type Dayjs = typeof dayjs

interface DayjsWrapper {
  instance: Dayjs
}

const createProxy = () => {
  return new Proxy(dayjs, {})
}

const dayjsAtom = atom<DayjsWrapper>({instance: createProxy()})

export function useDayjs(): Dayjs {
  return useAtomValue(dayjsAtom).instance
}

export function useSetDayjs(): Dispatch<(prevState: Dayjs) => Dayjs> {
  const setter = useSetAtom(dayjsAtom)

  return useCallback((value: ((prevState: Dayjs) => Dayjs)) => {
    setter((prevState: DayjsWrapper): DayjsWrapper => {
      value(prevState.instance)
      return {instance: createProxy()}
    })
  }, [setter])
}
