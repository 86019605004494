import useSWR from 'swr'
import type {MetaItem} from '../../@types/entity/Meta'
import {APP_VERSION} from '../../config'
import {createCollectionIri} from '../../utils/iri'

type GetResponse = {
  readonly meta?: MetaItem
  readonly error?: Error
}

export function useMeta(enabled = true): GetResponse {
  const {data, error} = useSWR(enabled ? createCollectionIri(`meta/${APP_VERSION}`) : undefined)

  return {
    meta: data,
    error,
  }
}
