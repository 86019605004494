import {type ComponentType, lazy, memo, type PropsWithChildren} from 'react'
import {createBrowserRouter, Navigate, Outlet, RouterProvider, useParams} from 'react-router'
import {getSpotUrl} from './hooks/useSpotNavigate'
import {CurrentSpotIdListener} from './pages/Dashboard/components/Navigation/CurrentSpotIdListener'
import {I18n} from './components/I18n'
import {Loader} from './components/Loader'
import {DashboardProgress} from './components/DashboardProgress'

const AdminBillingPage = lazy(async () => ({default: (await import('./pages/AdminBilling/AdminBillingPage')).AdminBillingPage}))
const AdminColorCreate = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorCreate')).AdminColorCreate}))
const AdminColorEdit = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorEdit')).AdminColorEdit}))
const AdminColorList = lazy(async () => ({default: (await import('./pages/AdminColor/AdminColorList')).AdminColorList}))
const AdminCommentEdit = lazy(async () => ({default: (await import('./pages/AdminComment/AdminCommentEdit')).AdminCommentEdit}))
const AdminCommentList = lazy(async () => ({default: (await import('./pages/AdminComment/AdminCommentList')).AdminCommentList}))
const AdminLaneCreate = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneCreate')).AdminLaneCreate}))
const AdminLaneEdit = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneEdit')).AdminLaneEdit}))
const AdminLaneList = lazy(async () => ({default: (await import('./pages/AdminLane/AdminLaneList')).AdminLaneList}))
const AdminMembershipCreate = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipCreate')).AdminMembershipCreate}))
const AdminMembershipEdit = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipEdit')).AdminMembershipEdit}))
const AdminMembershipList = lazy(async () => ({default: (await import('./pages/AdminMembership/AdminMembershipList')).AdminMembershipList}))
const AdminRouteCreate = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteCreate')).AdminRouteCreate}))
const AdminRouteEdit = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteEdit')).AdminRouteEdit}))
const AdminRouteList = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteList')).AdminRouteList}))
const AdminRouteReport = lazy(async () => ({default: (await import('./pages/AdminRoute/AdminRouteReport')).AdminRouteReport}))
const AdminSectorCreate = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorCreate')).AdminSectorCreate}))
const AdminSectorEdit = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorEdit')).AdminSectorEdit}))
const AdminSectorList = lazy(async () => ({default: (await import('./pages/AdminSector/AdminSectorList')).AdminSectorList}))
const AdminSpotCreate = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotCreate')).AdminSpotCreate}))
const AdminSpotEdit = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotEdit')).AdminSpotEdit}))
const AdminSpotList = lazy(async () => ({default: (await import('./pages/AdminSpot/AdminSpotList')).AdminSpotList}))
const AdminSettingsRouter = lazy(async () => ({default: (await import('./pages/AdminSettings/AdminSettingsRouter')).AdminSettingsRouter}))
const AdminSettings = lazy(async () => ({default: (await import('./pages/AdminSettings/AdminSettings')).AdminSettings}))
const AdminStats = lazy(async () => ({default: (await import('./pages/AdminStats/AdminStats')).AdminStats}))
const AppInstall = lazy(async () => ({default: (await import('./pages/AppInstall/AppInstall')).AppInstall}))
const BillingCallbackRouter = lazy(async () => ({default: (await import('./pages/BillingCallback/BillingCallbackRouter')).BillingCallbackRouter}))
const BillingSessionRouter = lazy(async () => ({default: (await import('./pages/BillingCallback/BillingSessionRouter')).BillingSessionRouter}))
const AuthLink = lazy(async () => ({default: (await import('./pages/Security/AuthLink')).AuthLink}))
const AuthPopup = lazy(async () => ({default: (await import('./pages/Security/AuthPopup')).AuthPopup}))
const DashboardOutlet = lazy(async () => ({default: (await import('./pages/Dashboard/DashboardOutlet')).DashboardOutlet}))
const ErrorHttp = lazy(async () => ({default: (await import('./pages/Error/ErrorHttp')).ErrorHttp}))
const Landing = lazy(async () => ({default: (await import('./pages/Landing/Landing')).Landing}))
const ResumeRedirect = lazy(async () => ({default: (await import('./pages/Landing/ResumeRedirect')).ResumeRedirect}))
const SpotCreate = lazy(async () => ({default: (await import('./pages/SpotCreate/SpotCreate')).SpotCreate}))
const RouteList = lazy(async () => ({default: (await import('./pages/RouteList/RouteList')).RouteList}))
const RouteView = lazy(async () => ({default: (await import('./pages/RouteView/RouteView')).RouteView}))
const SpotView = lazy(async () => ({default: (await import('./pages/SpotView/SpotView')).SpotView}))
const UserProfile = lazy(async () => ({default: (await import('./pages/UserProfile/UserProfile')).UserProfile}))
const UserView = lazy(async () => ({default: (await import('./pages/UserView/UserView')).UserView}))
const WorkoutList = lazy(async () => ({default: (await import('./pages/WorkoutList/WorkoutList')).WorkoutList}))
const WorkoutCategoryList = lazy(async () => ({default: (await import('./pages/WorkoutCategoryList/WorkoutCategoryList')).WorkoutCategoryList}))
const WorkoutView = lazy(async () => ({default: (await import('./pages/WorkoutView/WorkoutView')).WorkoutView}))

const NavigateToRoute = memo(() => {
  const {spotSlug} = useParams()
  const url = spotSlug ? getSpotUrl(spotSlug, '/routes') : '/'

  return <Navigate to={url} replace/>
})

const RouterOutlet = memo(() => {
  return <>
    <CurrentSpotIdListener/>
    <I18n>
      <Outlet/>
    </I18n>
  </>
})

const Router404 = memo(() => {
  return <LoaderDashboard>
    <ErrorHttp code={404}/>
  </LoaderDashboard>
})

const LoaderDashboard = memo(({children}: PropsWithChildren) => {
  return <Loader fallback={<DashboardProgress/>} errorMode="display">
    {children}
  </Loader>
})
const LoaderAdmin = memo(({children}: PropsWithChildren) => {
  return <Loader fallback={<DashboardProgress/>} >
    {children}
  </Loader>
})

const withLoader = (WrappedComponent: ComponentType, LoaderComponent: ComponentType<PropsWithChildren>) => {
  return () => <LoaderComponent>
    <WrappedComponent/>
  </LoaderComponent>
}

const workoutConfig = {
  path: 'workouts',
  children: [
    {
      index: true,
      Component: withLoader(WorkoutCategoryList, LoaderDashboard),
    },
    {
      path: ':category',
      children: [{
        index: true,
        Component: withLoader(WorkoutList, LoaderDashboard),
      }, {
        path: ':workoutId',
        Component: withLoader(WorkoutView, LoaderDashboard),
      },
      ],
    },
  ],
}

const profileConfig = {
  path: ':userId',
  children: [
    {
      path: '*',
      Component: withLoader(UserProfile, LoaderDashboard),
    },
  ],
}

const router = createBrowserRouter([{
  path: '/:lang?/',
  Component: RouterOutlet,
  children: [{
    path: 'auth/oauth/:provider',
    Component: withLoader(AuthPopup, LoaderDashboard),
  }, {
    path: 'spot/:spotSlug/billing/session/*',
    Component: withLoader(BillingSessionRouter, LoaderDashboard),
  }, {
    path: '*',
    Component: DashboardOutlet,
    children: [{
      index: true,
      Component: withLoader(Landing, LoaderDashboard),
    }, {
      path: 'auth/link',
      Component: withLoader(AuthLink, LoaderDashboard),
    }, {
      path: 'profile',
      Component: withLoader(UserView, LoaderDashboard),
      children: [profileConfig],
    },
    workoutConfig, {
      path: 'app',
      Component: withLoader(AppInstall, LoaderDashboard),
    }, {
      path: 'spot-create',
      Component: withLoader(SpotCreate, LoaderDashboard),
    }, {
      path: 'resume',
      Component: withLoader(ResumeRedirect, LoaderDashboard),
    }, {
      path: 'spot/:spotSlug',
      Component: withLoader(SpotView, LoaderDashboard),
      children: [{
        path: 'profile',
        children: [profileConfig],
      },
      workoutConfig, {
        path: 'billing/callback/*',
        Component: withLoader(BillingCallbackRouter, LoaderDashboard),
      }, {
        path: 'routes',
        Component: withLoader(RouteList, LoaderDashboard),
        children: [{
          path: ':routeId',
          Component: withLoader(RouteView, LoaderAdmin),
        }],
      }, {
        path: 'admin',
        children: [{
          path: 'routes',
          Component: withLoader(AdminRouteList, LoaderDashboard),
          children: [{
            path: 'new',
            Component: withLoader(AdminRouteCreate, LoaderAdmin),
          }, {
            path: 'report',
            Component: withLoader(AdminRouteReport, LoaderAdmin),
          }, {
            path: ':routeId',
            Component: withLoader(AdminRouteEdit, LoaderAdmin),
          }],
        }, {
          path: 'comments',
          Component: withLoader(AdminCommentList, LoaderDashboard),
          children: [{
            path: ':commentId',
            Component: withLoader(AdminCommentEdit, LoaderAdmin),
          }],
        }, {
          path: 'stats',
          children: [{
            path: ':x',
            children: [{
              path: ':y',
              Component: withLoader(AdminStats, LoaderDashboard),
            }, {
              path: '*',
              element: <Navigate to="count"/>,
            }],
          }, {
            path: '*',
            element: <Navigate to="grade/count"/>,
          }],
        }, {
          path: 'settings',
          Component: withLoader(AdminSettingsRouter, LoaderDashboard),
          children: [{
            path: 'global',
            Component: withLoader(AdminSettings, LoaderDashboard),
          }, {
            path: 'colors',
            Component: withLoader(AdminColorList, LoaderDashboard),
            children: [{
              path: 'new',
              Component: withLoader(AdminColorCreate, LoaderAdmin),
            }, {
              path: ':colorId',
              Component: withLoader(AdminColorEdit, LoaderAdmin),
            }],
          }, {
            path: 'sectors',
            Component: withLoader(AdminSectorList, LoaderDashboard),
            children: [{
              path: 'new',
              Component: withLoader(AdminSectorCreate, LoaderAdmin),
            }, {
              path: ':sectorId',
              Component: withLoader(AdminSectorEdit, LoaderAdmin),
            }],
          }, {
            path: 'spots',
            Component: withLoader(AdminSpotList, LoaderDashboard),
            children: [{
              path: 'new',
              Component: withLoader(AdminSpotCreate, LoaderAdmin),
            }, {
              path: ':spotId',
              Component: withLoader(AdminSpotEdit, LoaderAdmin),
            }],
          }, {
            path: 'lanes',
            Component: withLoader(AdminLaneList, LoaderDashboard),
            children: [{
              path: 'new',
              Component: withLoader(AdminLaneCreate, LoaderAdmin),
            }, {
              path: ':laneId',
              Component: withLoader(AdminLaneEdit, LoaderAdmin),
            }],
          }, {
            path: 'memberships',
            Component: withLoader(AdminMembershipList, LoaderDashboard),
            children: [{
              path: 'new',
              Component: withLoader(AdminMembershipCreate, LoaderAdmin),
            }, {
              path: ':membershipId',
              Component: withLoader(AdminMembershipEdit, LoaderAdmin),
            }],
          }, {
            path: 'billing',
            Component: withLoader(AdminBillingPage, LoaderDashboard),
          }, {
            path: '*',
            element: <Navigate to="./global" replace/>,
          }],
        }, {
          path: '*',
          Component: NavigateToRoute,
        }],
      }, {
        path: '*',
        Component: NavigateToRoute,
      }],
    }, {
      path: '*',
      element: <Router404/>,
    }],
  }],
}], {
  future: {
    v7_relativeSplatPath: true,
    v7_fetcherPersist: true,
    v7_normalizeFormMethod: true,
    v7_partialHydration: true,
    v7_skipActionErrorRevalidation: true,
  },
},
)

export const Router = memo(() => {
  return <RouterProvider router={router} />
})
