import {memo, type PropsWithChildren, type ReactNode, Suspense} from 'react'
import {ErrorBoundary, type ErrorBoundaryMode} from './ErrorBoundary'

interface Props extends PropsWithChildren<any> {
  fallback?: ReactNode
  errorMode?: ErrorBoundaryMode
}

export const Loader = memo(({fallback, errorMode, children}: Props) => {
  return <ErrorBoundary mode={errorMode}>
    <Suspense fallback={fallback}>
      {children}
    </Suspense>
  </ErrorBoundary>
})
