import {type StorageHandler, timestampStorageHandler, useCacheProvider} from '@piotr-cz/swr-idb-cache'
import {memo, type PropsWithChildren} from 'react'
import {type Revalidator, type RevalidatorOptions, SWRConfig} from 'swr'
import type {PublicConfiguration} from 'swr/_internal'
import {Loading} from '../Loading'
import {useErrorHandler} from '../hooks/useErrorHandler'
import {ApiError} from '../utils/ApiError'
import {fetchApiResource} from '../utils/fetchApi'

const maxRetryBackoff = 4
const errorRetryInterval = 1000
const maxAge = 7 * 24 * 60 * 60 * 1e3

type Data = any
type StoreObject = {
  value: Data
  ts: number
}

const gcStorageHandler: StorageHandler<Data, StoreObject> = {
  ...timestampStorageHandler,
  // Revive each entry only when it's timestamp is newer than expiration
  revive: (key, storeObject) =>
    storeObject.ts > Date.now() - maxAge ? timestampStorageHandler.revive(key, storeObject) : undefined,
}

const handleRetry = (err: Error, key: string, config: Readonly<PublicConfiguration<any, Error, any>>, revalidate: Revalidator, revalidateOpts: Required<RevalidatorOptions>): void => {
  // Never retry on 402 nor 422 nor 404.
  if (err instanceof ApiError && [402, 422, 404].includes(err.statusCode)) {
    return
  }

  // fast on 401.
  if (err instanceof ApiError && err.statusCode === 401) {
    revalidate(revalidateOpts)
    return
  }

  // Exponential backoff
  const jitter = 1 + ((Math.random() - 0.5) / 5)
  const delay = ~~(jitter * (1 << (Math.min(revalidateOpts.retryCount, maxRetryBackoff))) * config.errorRetryInterval)

  setTimeout(
    () => revalidate(revalidateOpts),
    delay,
  )
}

export const Swr = memo(({children}: PropsWithChildren) => {
  const errorHandler = useErrorHandler()

  const cacheProvider = useCacheProvider({
    dbName: 'verticaltopo',
    storeName: 'swr-cache',
    storageHandler: gcStorageHandler,
    version: 3,
  })

  // Cache Provider is being initialized - render fallback component in the meantime
  if (!cacheProvider) {
    return <Loading/>
  }

  return <SWRConfig
    value={{
      errorRetryInterval,
      onError: errorHandler,
      fetcher: fetchApiResource,
      onErrorRetry: handleRetry,
      provider: cacheProvider,
      dedupingInterval: 60000,
    }}
  >
    {children}
  </SWRConfig>
})
