import {Box, Container, Typography} from '@mui/material'
import {memo, type PropsWithChildren, type ReactNode} from 'react'

interface Props extends PropsWithChildren {
  title: ReactNode
  body?: ReactNode
}

export const ErrorContainer = memo(({title, body, children}: Props) => {
  return <Container maxWidth="md">
    <Typography variant="h4" sx={{mb: 5}}>{title}</Typography>
    {body && <Typography variant="body2">{body}</Typography>}
    {children && <Box sx={{mt: 8, mb: 4, display: 'flex', justifyContent: 'center'}}>
      {children}
    </Box>}
  </Container>
})
