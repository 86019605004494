import {Box} from '@mui/material'
import {memo, type PropsWithChildren, type ReactNode} from 'react'
import {ErrorContainer} from './ErrorContainer'

export interface ErrorPageProps extends PropsWithChildren {
  title: ReactNode
  body?: ReactNode
}

export const ErrorPage = memo((props: ErrorPageProps) => {
  return <Box sx={{mt: {xs: 10, sm: 20}}}>
    <ErrorContainer {...props}/>
  </Box>
})
