import {STATS_ID, STATS_SCRIPT} from '../config'

if (STATS_ID && STATS_SCRIPT) {
  const script = document.createElement('script')
  script.async = true
  script.defer = true
  script.src = STATS_SCRIPT
  script.setAttribute('data-website-id', STATS_ID)

  document.body.appendChild(script)
}
