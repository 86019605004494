import {useMemo, useRef} from 'react'
import type {FetchOptions} from '../@types/fetch'
import {fetchApiResource} from '../utils/fetchApi'
import {useEffectEvent} from './useEffectEvent'

interface Hook {
  readonly fetcher: (key: string, init?: FetchOptions) => any
  readonly abortAll: () => void
}

export function useFetcher(): Hook {
  const controllers = useRef(new Map<AbortController, null>())

  const fetcher = useEffectEvent(async (key: string, init?: FetchOptions) => {
    const controller = new AbortController()
    controllers.current.set(controller, null)

    try {
      return await fetchApiResource(key, {
        ...init,
        signal: controller.signal,
      })
    } finally {
      controllers.current.delete(controller)
    }
  })

  const abortAll = useEffectEvent((): void => {
    if (!controllers.current) {
      return
    }

    for (const controller of controllers.current.keys()) {
      controller.abort()
    }

    controllers.current.clear()
  })

  return useMemo(() => ({
    fetcher,
    abortAll,
  }), [fetcher, abortAll])
}
