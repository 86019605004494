import {Component, type PropsWithChildren, type ReactNode} from 'react'
import {ErrorError} from '../pages/Error/ErrorError'
import {RectangularSkeleton} from './Skeleton/RectangularSkeleton'

export type ErrorBoundaryMode = 'display' | 'log'

interface Props extends PropsWithChildren<any> {
  mode?: ErrorBoundaryMode
}

interface State {
  error?: Error
}

export class ErrorBoundary extends Component<Props, State> {
  public override state: State = {
    error: undefined,
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      error,
    }
  }

  public override render(): ReactNode {
    if (this.state.error) {
      switch (this.props.mode) {
        case 'display':
          return <ErrorError error={this.state.error}/>

        default:
          console.error(this.state.error)
          return undefined
      }
    }

    return this.props.children
  }
}

interface RectangleErrorBoundaryProps extends Props {
  height: number
  width: number
}

export class RectangleErrorBoundary extends Component<RectangleErrorBoundaryProps, State> {
  public override state: State = {
    error: undefined,
  }

  public static getDerivedStateFromError(error: Error): State {
    return {
      error,
    }
  }

  public override render(): ReactNode {
    if (this.state.error) {
      switch (this.props.mode) {
        case 'display':
          return <RectangularSkeleton error={this.state.error}/>

        default:
          console.error(this.state.error)
          return undefined
      }
    }

    return this.props.children
  }
}
