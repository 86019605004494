import {Skeleton as MuiSkeleton, type Theme} from '@mui/material'
import type {SxProps} from '@mui/system'
import {memo, useMemo} from 'react'
import {TimeoutError} from '../../utils/ApiError'
import {styled} from '@mui/material/styles'

const StyledMuiSkeleton = memo(styled(MuiSkeleton)`
    &.timeoutError {
        background-color: rgba(0, 0, 0, 0.4)
    }   
    &.genericError {
        background-color: rgba(127, 0, 0, 0.11)
    }   
`)

export interface PropsWithError {
  error?: Error
}

export interface SkeletonProps extends PropsWithError {
  height?: number | string
  width?: number | string
  variant?: 'text' | 'rounded' | 'circular'
  sx?: SxProps<Theme>
}

const getClassname = (error: Error | undefined): string | undefined => {
  if (!error) {
    return undefined
  }

  if (error instanceof TimeoutError) {
    return 'timeoutError'
  }

  return 'genericError'
}

export const Skeleton = memo(({error, ...props}: SkeletonProps) => {
  const className = useMemo(() => getClassname(error), [error])

  return <StyledMuiSkeleton
    className={className}
    animation="wave"
    {...props}
  />
})
