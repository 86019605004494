import {useParams} from 'react-router'
import useSWR, {useSWRConfig} from 'swr'
import type {Iri} from '../../@types/Api'
import type {UserCollection, UserCurrent, UserItem, UserPartial} from '../../@types/entity/User'
import type {FetchOptions} from '../../@types/fetch'
import type {CollectionMap} from '../../@types/hydra/HydraCollection'
import type {HydraResource} from '../../@types/hydra/HydraResource'
import type {FetchOption} from '../../components/EnhancedTable/types'
import {useLogged} from '../../states/auth'
import {createCollectionIri, createItemIri} from '../../utils/iri'
import {type FilterOption, filterToQueryString, useMap, usePatch, usePost} from '../useApi'
import {useAuthenticatedFetcher} from '../useAuth'
import {useEffectEvent} from '../useEffectEvent'
import {useFetcher} from '../useFetcher'
import {useMutateResource} from '../useMutate'

export type UserMap = CollectionMap<UserPartial>
const resource = createCollectionIri('users')

type GetCurrentResponse = {
  readonly user?: UserCurrent
  readonly error?: Error
}

type GetResponse = {
  readonly user?: UserItem
  readonly error?: Error
}

type CollectionResponse = {
  readonly users?: UserCollection
  readonly error?: Error
}

type MapResponse = {
  readonly userMap?: UserMap
  readonly error?: Error
}

type UserCollectionFilter = {
  readonly userIri?: Iri
  readonly userIris?: Iri[]
}
const collectionFilterOptions: FilterOption<UserCollectionFilter> = {
  userIri: 'id',
  userIris: 'id',
}

export const useUserGet = (userIri?: Iri): GetResponse => {
  const {data, error} = useSWR(userIri)

  return {
    user: data,
    error,
  }
}

export const useUserHelperGetCurrent = (): GetCurrentResponse => {
  const logged = useLogged()
  const authenticatedFetcher = useAuthenticatedFetcher()
  const {data, error} = useSWR(logged ? createCollectionIri('me') : undefined, authenticatedFetcher)

  if (data) {
    data.isCurrent = true
  }
  return {
    user: data,
    error,
  }
}

export const useUserHelperGetOrCurrent = (userIri?: Iri | 'me'): GetResponse => {
  const isCurrent = userIri === 'me'
  const {user: currentUser, error: currentUserError} = useUserHelperGetCurrent()

  const {data, error} = useSWR(isCurrent ? undefined : userIri)

  if (isCurrent || (data && currentUser && data?.['@id'] === currentUser?.['@id'])) {
    return {
      user: currentUser,
      error: currentUserError,
    }
  }

  return {
    user: data,
    error,
  }
}

export const useUserCollection = (filter: UserCollectionFilter | undefined, fetchOptions?: FetchOption, enabled = true): CollectionResponse => {
  const {
    empty,
    querystring,
  } = filterToQueryString<UserCollectionFilter>(collectionFilterOptions, enabled ? filter : undefined, fetchOptions)
  const {data, error} = useSWR(enabled && !empty ? `${createCollectionIri('users')}?${querystring.toString()}` : null)
  return {
    users: data,
    error,
  }
}

export const useUserIriFromLocation = (): string | undefined => {
  const {userId} = useParams()

  if (userId === 'me') {
    return 'me'
  }

  return createItemIri('users', userId)
}

export const useUserCollectionToMap = ({users, error}: CollectionResponse): MapResponse => {
  const userMap = useMap<UserPartial>(users)

  if (error) {
    return {
      error,
    }
  }
  if (!users) {
    return {}
  }

  return {
    userMap,
  }
}

export function useUsersFetcher(): (qs: Record<string, string>, init: FetchOptions) => Promise<UserCollection> {
  const {fetcher} = useFetcher()

  return useEffectEvent((qs: Record<string, string> = {}, init: FetchOptions = {}): Promise<UserCollection> => {
    let url = resource
    if (qs) {
      const querystring = new URLSearchParams(qs)
      url = `${url}?${querystring.toString()}`
    }

    return fetcher(url, init)
  })
}

export function useUserAvatarUpdate(): (user: UserPartial, image: Blob) => Promise<UserCurrent> {
  const authenticatedFetcher = useAuthenticatedFetcher()
  const {mutate} = useSWRConfig()

  return async function userAvatarUpdate(user: UserPartial, image: Blob) {
    const data = new FormData()
    data.append('image', image)

    const response = (await authenticatedFetcher(`${user['@id']}/avatar`, {
      method: 'POST',
      body: data,
    })) as UserCurrent
    await mutate(createCollectionIri('me'), response)
    response.isCurrent = true

    return response
  }
}

type CreatePayload = {
  email: string
  spot: Iri
}

type UpdatePayload = {
  name?: string
  routeScale?: null | string
  boulderScale?: null | string
  notifyRoute?: boolean
  notifyComment?: boolean
  suggestRoute?: boolean
  locale?: string
}

export function useUserUpdate(): (user: UserPartial, payload: UpdatePayload) => Promise<UserCurrent> {
  const {mutate} = useSWRConfig()
  const handler = usePatch<UserCurrent>()

  return async function userUpdate(user: UserPartial, payload: UpdatePayload) {
    const response = await handler(user, payload)
    await mutate(createCollectionIri('me'), response)
    response.isCurrent = true

    return response
  }
}

export function useUserCreate(): (payload: CreatePayload) => Promise<UserItem> {
  return usePost<UserItem>('users')
}

export function useUserDelete(): (spot: UserPartial) => Promise<void> {
  const authenticatedFetcher = useAuthenticatedFetcher()
  const {mutate} = useSWRConfig()
  const mutateResource = useMutateResource()

  return useEffectEvent(async (item: HydraResource) => {
    await authenticatedFetcher(item['@id'], {
      method: 'DELETE',
    })
    setTimeout(async () => {
      await mutateResource(item['@id'])
      await mutate(createCollectionIri('me'))
    }, 10)
  })
}
