import '@fontsource/roboto/latin.css'
import './i18n'
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {SnackbarProvider} from 'notistack'
import {Router} from './Router'
import {AppInit} from './components/AppInit'
import {AppTheme} from './components/AppTheme'
import {ReleaseHandler} from './components/ReleaseHandler'
import {Swr} from './components/Swr'
import {AuthListener} from './pages/Dashboard/components/Navigation/AuthListener'
import {lazy, type ReactNode} from 'react'
import {Loader} from './components/Loader'

const Sentry = lazy(async () => ({default: (await import('./pages/Dashboard/Sentry')).Sentry}))

export const App = (): ReactNode => {
  return <LocalizationProvider dateAdapter={AdapterDayjs}>
    <AppInit/>
    <AppTheme>
      <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}>
        <Swr>
          <ReleaseHandler/>
          <AuthListener/>
          <Loader>
            <Sentry/>
          </Loader>
          <Router/>
        </Swr>
      </SnackbarProvider>
    </AppTheme>
  </LocalizationProvider>
}
