import {memo, useEffect} from 'react'
import {APP_VERSION} from '../config'
import {useUsage} from '../hooks/useUsage'

export const AppInit = memo(() => {
  const usage = useUsage()
  useEffect(() => {
    usage('app loaded', {version: APP_VERSION})
  }, [usage])

  return null
})
