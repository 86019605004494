import {useRef} from 'react'

type AnyFunction = (...args: any[]) => any

/**
 * Similar to useCallback, with a few subtle differences:
 * - The returned function is a stable reference, and will always be the same between renders
 * - No dependency lists required
 * - Properties or state accessed within the callback will always be "current"
 */
export function useEffectEvent<T extends AnyFunction>(callback: T): T {
  // Keep track of the latest callback:
  const latestRef = useRef<T>(callback)
  latestRef.current = callback

  // Create a stable callback that always calls the latest callback:
  // using useRef instead of useCallback avoids creating and empty array on every render
  const stableRef = useRef<T>(null as any)
  if (!stableRef.current) {
    stableRef.current = function (this: any, ...args) {
      return latestRef.current.apply(this, args)
    } as T
  }

  return stableRef.current
}
