import i18n, {type ReadCallback} from 'i18next'
import i18nextBrowserLanguageDetector from 'i18next-browser-languagedetector'
import i18nextResourcesToBackend from 'i18next-resources-to-backend'
import {initReactI18next} from 'react-i18next'
import 'intl-pluralrules'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/en'
import 'dayjs/locale/fr'
import {localeCodes} from './enums/localeCode'

i18n
  .use(
    i18nextResourcesToBackend((language: string, namespace: string, callback: ReadCallback) => {
      import(`./locales/${language}/index.ts`)
        .then((resources) => {
          callback(null, resources.default[namespace])
        })
        .catch((error) => {
          callback(error, null)
        })
    }),
  )
  .use(i18nextBrowserLanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    nonExplicitSupportedLngs: true,
    supportedLngs: localeCodes,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    detection: {
      order: ['path', 'localStorage', 'sessionStorage', 'navigator'],
    },
  })

dayjs.extend(localizedFormat)
dayjs.extend(duration)
