import type {Iri} from '../@types/Api'
import type {CollectionMap} from '../@types/hydra/HydraCollection'
import type {HydraResource} from '../@types/hydra/HydraResource'

export function mapItems<T extends HydraResource>(items: T[] | undefined): CollectionMap<T> {
  const collectionMap: CollectionMap<T> = new Map()
  if (items) {
    for (const item of items) {
      collectionMap.set(item['@id'], item)
    }
  }

  return collectionMap
}

export function mapFilter<T extends HydraResource>(map: CollectionMap<T>, callback: (item: T, key: Iri) => boolean): CollectionMap<T> {
  const collectionMap: CollectionMap<T> = new Map()
  for (const [key, item] of map) {
    if (callback(item, key)) {
      collectionMap.set(key, item)
    }
  }

  return collectionMap
}
